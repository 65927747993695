

html {
  max-width: 1200px;
  margin: 0 auto;
  background: url("https://thrillparkprague.com/resources/css/img/tp_web2018_bg_dark_png8.png");
  -webkit-overflow-scrolling: 'touch',

}

body {
  min-height: 100vh;
}

.MuiPickersToolbar-penIconButton {
  display: none;
}

.PrivatePickersYear-root,
.MuiPickersCalendarHeader-label,
.MuiTelInput-MenuItem	{
  color: #000 !important;
}

.MuiDatePickerToolbar-title {
  color: #000 !important;
}

.MuiPickersDay-root {
  color: #000;
}

.group-size-background {
  fill: #181818;
}

.group-size-outline-inactive {
  fill: #4d4d4d;
}

.group-size-outline-active {
  fill: #bd1213;
}

.group-size-number-inactive {
  fill: #fff;
}

.group-size-number-active {
  fill: red;
}